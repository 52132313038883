export default defineAppConfig({
  ui: {
    primary: 'byhours',
    button: {
      rounded: "rounded-full",
      padding: {
        sm: 'px-4 py-2.5',
        lg: 'px-6 py-3',
        xl: 'px-8 py-3.5'
      }
    }
  },
  icon: {
    provider: 'iconify',
    serverBundle: false,
  }
})