
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91city_93_45_91id_93_46htmldEuq7gvUfmMeta } from "/app/pages/[city]-[id].html.vue?macro=true";
import { default as _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta } from "/app/pages/[hotel]-[id].html.vue?macro=true";
import { default as como_45funciona_46htmlWKeIQd7BuzMeta } from "/app/pages/como-funciona.html.vue?macro=true";
import { default as destinations2nnGO10igbMeta } from "/app/pages/destinations.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "city-id.html___es",
    path: "/es/:city-:id(\\d+[dn]).html",
    meta: _91city_93_45_91id_93_46htmldEuq7gvUfmMeta || {},
    component: () => import("/app/pages/[city]-[id].html.vue")
  },
  {
    name: "city-id.html___en",
    path: "/en/:city-:id(\\d+[dn]).html",
    meta: _91city_93_45_91id_93_46htmldEuq7gvUfmMeta || {},
    component: () => import("/app/pages/[city]-[id].html.vue")
  },
  {
    name: "city-id.html___de",
    path: "/de/:city-:id(\\d+[dn]).html",
    meta: _91city_93_45_91id_93_46htmldEuq7gvUfmMeta || {},
    component: () => import("/app/pages/[city]-[id].html.vue")
  },
  {
    name: "city-id.html___it",
    path: "/it/:city-:id(\\d+[dn]).html",
    meta: _91city_93_45_91id_93_46htmldEuq7gvUfmMeta || {},
    component: () => import("/app/pages/[city]-[id].html.vue")
  },
  {
    name: "city-id.html___fr",
    path: "/fr/:city-:id(\\d+[dn]).html",
    meta: _91city_93_45_91id_93_46htmldEuq7gvUfmMeta || {},
    component: () => import("/app/pages/[city]-[id].html.vue")
  },
  {
    name: "hotel-id.html___es",
    path: "/es/:hotel-:id(\\d+).html",
    meta: _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta || {},
    component: () => import("/app/pages/[hotel]-[id].html.vue")
  },
  {
    name: "hotel-id.html___en",
    path: "/en/:hotel-:id(\\d+).html",
    meta: _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta || {},
    component: () => import("/app/pages/[hotel]-[id].html.vue")
  },
  {
    name: "hotel-id.html___de",
    path: "/de/:hotel-:id(\\d+).html",
    meta: _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta || {},
    component: () => import("/app/pages/[hotel]-[id].html.vue")
  },
  {
    name: "hotel-id.html___it",
    path: "/it/:hotel-:id(\\d+).html",
    meta: _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta || {},
    component: () => import("/app/pages/[hotel]-[id].html.vue")
  },
  {
    name: "hotel-id.html___fr",
    path: "/fr/:hotel-:id(\\d+).html",
    meta: _91hotel_93_45_91id_93_46htmlkUnRIcBpgmMeta || {},
    component: () => import("/app/pages/[hotel]-[id].html.vue")
  },
  {
    name: "como-funciona.html___es",
    path: "/es/como-funciona.html",
    component: () => import("/app/pages/como-funciona.html.vue")
  },
  {
    name: "como-funciona.html___en",
    path: "/en/como-funciona.html",
    component: () => import("/app/pages/como-funciona.html.vue")
  },
  {
    name: "como-funciona.html___de",
    path: "/de/como-funciona.html",
    component: () => import("/app/pages/como-funciona.html.vue")
  },
  {
    name: "como-funciona.html___it",
    path: "/it/como-funciona.html",
    component: () => import("/app/pages/como-funciona.html.vue")
  },
  {
    name: "como-funciona.html___fr",
    path: "/fr/como-funciona.html",
    component: () => import("/app/pages/como-funciona.html.vue")
  },
  {
    name: "destinations___es",
    path: "/es/destinations",
    component: () => import("/app/pages/destinations.vue")
  },
  {
    name: "destinations___en",
    path: "/en/destinations",
    component: () => import("/app/pages/destinations.vue")
  },
  {
    name: "destinations___de",
    path: "/de/destinations",
    component: () => import("/app/pages/destinations.vue")
  },
  {
    name: "destinations___it",
    path: "/it/destinations",
    component: () => import("/app/pages/destinations.vue")
  },
  {
    name: "destinations___fr",
    path: "/fr/destinations",
    component: () => import("/app/pages/destinations.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "search___es",
    path: "/es/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___it",
    path: "/it/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]