import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import 'vue-leaflet-markercluster/dist/style.css'

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      L
    }
  }
})