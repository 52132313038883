export const useDeviceStore = defineStore('device', {
  state: () => ({
    isMobile: false
  }),
  actions: {
    detectDevice(userAgent: string): void {
      // Detectar si es un dispositivo móvil
      this.isMobile = /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
  }
});