export default defineNuxtPlugin(() => {
  const deviceStore = useDeviceStore();
  const headers = useRequestHeaders(['user-agent']) // Obtener solo el encabezado necesario

  if (headers['user-agent']) {
    // Si hay un 'user-agent', estamos en el servidor
    const userAgent = headers['user-agent'] || ''
    deviceStore.detectDevice(userAgent)
  } else if (typeof window !== 'undefined') {
    // En el cliente, usar el navegador como fallback
    const userAgent = navigator.userAgent || ''
    deviceStore.detectDevice(userAgent)
  }
})